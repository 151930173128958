<template>
  <card-form :loading="loading">
    <div class="row">
      <div class="col-md-2 text-center">
        <p class="m-0">Edição de usuário</p>
        <i class="uil uil-user-circle" style="font-size: 5rem;"></i>
      </div>
      <div class="col-md-10">
        <p>Este usuário é um(a)?</p>
        <div class="mb-3 d-flex flex-row" style="gap: 1rem;">
          <div v-for="atribuicao in atribuicoes" :key="atribuicao.id">
            <input
              type="radio"
              :disabled="disabled"
              class="form-check-input me-1"
              :id="`atribuicao-${atribuicao.id}`"
              v-model="atribuicaoSelecionada"
              :value="atribuicao"
            />
            <label class="form-check-label" :for="`atribuicao-${atribuicao.id}`">
              {{ atribuicao.nome }}
            </label>
          </div>
        </div>

        <p>Representa?</p>
        <div class="d-flex flex-row" style="gap: 1rem;">
          <div v-for="representacao in representacoes" :key="representacao.id">
            <input
              type="radio"
              :disabled="disabled"
              class="form-check-input me-1"
              :id="`representacao-${representacao.id}`"
              v-model="model.representacaoId"
              :value="representacao.id"
              @click="changeRepresentacao()"
            />
            <label class="form-check-label" :for="`representacao-${representacao.id}`">
              {{ representacao.nome }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="separador my-4"></div>

    <div class="row">
      <f-input 
        name="nome" 
        :disabled="disabled"
        v-model="model.nome" 
        v-validate="{ required: true }"
        data-vv-as="Nome"
        label="Nome"
        :modelErrors="errors"
        :cols="3"
      />
      <f-switch
        name="status"
        :disabled="disabled"
        v-model="model.status"
        :cols="1"
        :label="model.status ? 'Ativo' : 'Inativo'"
        legend="Status"
      />
      <f-input 
        name="email" 
        :disabled="disabled"
        v-model="model.email" 
        v-validate="{ required: true, email: true }"
        data-vv-as="E-mail"
        label="E-mail"
        :modelErrors="errors"
        :cols="5"
      />
      <f-input 
        name="dataAdd" 
        :disabled="true"
        :value="toStringDatetime(model.dataAdd)"
        label="Criado em"
        :cols="3"
      />
      <f-input 
        name="cpf" 
        :disabled="disabled"
        v-model="model.cpf" 
        v-mask="'###.###.###-##'"
        v-validate="{ required: true, cpf: true }"
        data-vv-as="CPF"
        label="CPF"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input v-if="atribuicaoSelecionada.camposObrigatorios.includes('crm')"
        name="crm" 
        :disabled="disabled"
        v-model="model.crm" 
        v-validate="{ required: true }"
        data-vv-as="CRM"
        label="CRM"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input v-if="atribuicaoSelecionada.camposObrigatorios.includes('coren')"
        name="coren" 
        :disabled="disabled"
        v-model="model.coren" 
        v-validate="{ required: true }"
        data-vv-as="COREN"
        label="COREN"
        :modelErrors="errors"
        :cols="3"
      />
      <div class="col-md-3" v-if="atribuicaoSelecionada.camposObrigatorios.length == 0"></div>
      <f-input 
        name="celular" 
        :disabled="disabled"
        v-model="model.celular" 
        v-mask="'(##) #####-####'"
        v-validate="{ required: true }"
        data-vv-as="Celular"
        label="Celular"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input 
        name="ultimoLogin" 
        :disabled="true"
        :value="toStringDatetime(model.ultimoLogin)"
        label="Último login"
        :cols="3"
      />
      
    </div>

    <div class="separador my-4"></div>

    <div class="row">
      <div class="col-md-1 d-flex align-items-center">
        <label class="m-0">Perfis</label>
      </div>
      <f-selectsearch
        name="perfis"
        ref="perfis"
        v-model="model.perfis"
        :disabled="disabled"
        placeholder="Digite para pesquisar o perfil"
        :searchFunction="pesquisarPerfil"
        @select="selecionarPerfil"
        :multiple="true"
        :cols="11"
      />
    </div>
      
    <div class="separador my-4"></div>

    <div class="row">
      <div class="col-md-1 d-flex align-items-center">
        <label class="m-0">Convênios</label>
      </div>
      <f-selectsearch
        name="convenios"
        ref="convenios"
        v-model="model.convenios"
        :disabled="disabled"
        placeholder="Digite para pesquisar o convênio"
        :searchFunction="pesquisarConvenio"
        @select="selecionarConvenio"
        :multiple="true"
        :cols="11"
      />
    </div>
    
    <div class="separador my-4"></div>

    <div class="row">
      <div class="col-md-1 d-flex align-items-center">
        <label class="m-0">Hospitais</label>
      </div>
      <f-selectsearch
        name="hospitais"
        ref="hospitais"
        v-model="model.hospitais"
        :disabled="disabled"
        placeholder="Digite para pesquisar o hospital"
        :searchFunction="pesquisarHospital"
        @select="selecionarHospital"
        :multiple="true"
        :cols="11"
      />
    </div>
    
    <div>
      <div class="separador my-4"></div>
      <div class="row">
        <label>Assinatura</label>
        <div>
          <img v-if="assinatura != null" 
            :src="assinatura" 
            style="max-width: 240px; max-height: 120px; width: auto;" 
            class="mt-2 mb-4 mr-3 me-4" />

          <span v-if="hideUpload" @click="()=> { hideUpload = false; assinatura = null; }"><i class="fas fa-sync-alt fa-3x c-pointer" title="Alterar assinatura"></i></span> 
        </div>
        
        <input v-if="!disabled && !hideUpload" 
          accept="image/*" 
          type="file" 
          @change="uploadFile" 
          ref="file" />
        
      </div>
    </div>

    <div v-if="!disabled && model.id == 0">
      <div class="separador my-4"></div>
      <div class="row">
        <f-input 
          name="novaSenha"
          type="password"
          v-model="model.novaSenha" 
          v-validate="{ required: model.id == 0 }"
          data-vv-as="Senha do usuário"
          :label="model.id > 0 ? 'Alterar senha do usuário' : 'Senha do usuário'"
          :modelErrors="errors"
          :cols="3"
        />
      </div>
    </div>
    <div v-if="!disabled && model.id > 0">
      <div class="separador my-4"></div>
      <button class="btn btn-warning px-5" @click="() => { exibirSenha = true; }" type="button">Alterar senha do usuário</button>
    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'sistema.usuario.lista'})}" type="button">Voltar</button>
    </template>

    <b-modal
      v-model="exibirSenha"
      v-if="model.id > 0 && !disabled"
      ref="modal-senha"
      title="Alterar senha"
      :hideHeaderClose="true"
      :centered="true"
    >
      <div class="filtro-inline">
        <b-form-group label="Nova Senha:">
          <b-input type="password" v-model="senha" />
        </b-form-group>
        <b-form-group label="Confirmar Senha:">
          <b-input type="password" v-model="confirmarSenha" />
        </b-form-group>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="me-2"
            @click="() => { exibirSenha = false; }"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="me-2"
            @click="confirmAlterarSenha()"
          >
            Alterar
          </b-button>
        </div>
      </template>

    </b-modal>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { Validator } from 'vee-validate';
import { 
  getUsuario, criarUsuario, atualizarUsuario, 
  getAllAtribuicao, getAllRepresentacao,
  getLabelsConvenios, getLabelsHospitais, getLabelsPerfis,
  getUsuarioAssinatura, alterarSenha
} from "@/services/usuario.service";
import { trataErro, trataInsertSucesso, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import { messageError, messageSuccess } from '../../../../utils/messages'
import validateCpf from '../../../../utils/validator.cpf'
import { toStringDatetime } from '../../../../utils/mixins.datetime'
import FSelectsearch from '@/components/Form/SelectSearch';

export default {
  components: {
    CardForm,
    FSelectsearch
  },
  data() {
    return {
      toStringDatetime,

      loading: true,
      disabled: true,
      hideUpload: false,

      exibirSenha: false,
      confirmarSenha: '',
      senha: '',

      model: {
        id: 0,
        nome: '',
        status: true,
        email: '',
        celular: '',
        ultimoLogin: '',
        atribuicaoId: 0,
        representacaoId: 0,
        cpf: '',
        crm: '',
        coren: '',
        dataAdd: '',
        modulosUsuario: [],
        perfis: [],
        convenios: [],
        hospitais: [],
        novaSenha: '',
        assinaturaId: null
      },
      atribuicoes: [],
      atribuicaoSelecionada: {
        camposObrigatorios: [],
      },
      representacoes: [],
      assinaturaFile: null,
      assinatura: null
    };
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;

    Promise.all([
      (
        (this.$route.params.id && this.$route.params.id > 0) ?
        getUsuario(this.$route.params.id) :
        Promise.resolve({ data: { resultado: this.model } })
      ),
      getAllAtribuicao(this.$route.params.id),
      getAllRepresentacao(this.$route.params.id)
    ])
    .then((resultados) => {
      let resObjs = resultados[0].data.resultado;
      for (let key in resObjs) {
        this.model[key] = resObjs[key];
      }
      
      if (this.model.assinaturaId != null) {
        getUsuarioAssinatura(this.model.assinaturaId).then((r) => {
          this.hideUpload = true;
          this.assinatura = r.data
        })
      }

      this.atribuicoes = resultados[1].data.resultado;
      this.representacoes = resultados[2].data.resultado;

      // Destaca atribuicão pré-selecionada.
      if (this.model.atribuicaoId > 0) {
        for(let i = 0; i < this.atribuicoes.length; i++) {
          if (this.atribuicoes[i].id == this.model.atribuicaoId) {
            this.atribuicaoSelecionada = this.atribuicoes[i];
            break;
          }
        }
      }

      // Perfis, Convênios e Hospitais.
      if (this.model.convenios && this.model.convenios.length > 0) {
        this.$refs.convenios.setInitialValue(this.model.convenios);
      }
      if (this.model.hospitais && this.model.hospitais.length > 0) {
        this.$refs.hospitais.setInitialValue(this.model.hospitais);
      }
      if (this.model.perfis && this.model.perfis.length > 0) {
        this.$refs.perfis.setInitialValue(this.model.perfis);
      }
    })
    .catch((e) => 
      trataErro(e, this)
    )
    .finally(() => {
      this.loading = false;
    });
    
    Validator.extend('cpf', validateCpf);
  },
  methods: {
    confirmAlterarSenha() {
      if (this.senha.trim() == '' || this.senha != this.confirmarSenha) {
        messageError(this, 'Você deve preencher a senha e ela deve ser igual a confirmação da senha.')
      } else {
        alterarSenha(this.model.id, this.senha).then(() => {
          this.senha = '';
          this.confirmarSenha = '';
          this.exibirSenha = false;
          messageSuccess(this, 'Senha alterada com sucesso.')
        });
      }
    },
    uploadFile() {
      if (!this.$refs.file.files[0].type.startsWith("image/")) {
        this.$refs.file.value = null
        messageError(this, 'Tipo não suportado.')
        return;
      }
      this.assinaturaFile = this.$refs.file.files[0];
    },
    changeRepresentacao() {
      if (!this.disabled) {
        this.model.perfis = [];
        this.$refs.perfis.clean();
      }
    },
    async submeter(event) {
      event.preventDefault()

      var isValid = await this.$validator.validateAll();
      var atribuicaoValida = this.atribuicaoSelecionada && this.atribuicaoSelecionada.id > 0;
      var representacaoValida = this.model.representacaoId  != '' && this.model.representacaoId  > 0;

      if (!atribuicaoValida || !representacaoValida) {
        messageError(this, 'Atribuição e representação são obrigatórias.')
      }

      if (isValid && atribuicaoValida && representacaoValida) {
        if (this.atribuicaoSelecionada && this.atribuicaoSelecionada.id > 0) {
          this.model.atribuicaoId = this.atribuicaoSelecionada.id;
        }

        var fixModel = JSON.parse(JSON.stringify(this.model));

        if (fixModel.convenios && fixModel.convenios.length > 0) {
          fixModel.convenios = fixModel.convenios.map(m => m.id);
        }
        if (fixModel.hospitais && fixModel.hospitais.length > 0) {
          fixModel.hospitais = fixModel.hospitais.map(m => m.id);
        }
        if (fixModel.perfis && fixModel.perfis.length > 0) {
          fixModel.perfis = fixModel.perfis.map(m => m.id);
        }

        const formData = new FormData();
        Object.keys(fixModel).forEach(key => { 
          if (key == 'convenios' || key == 'hospitais' || key == 'perfis') {
            for (let index = 0; index < fixModel[key].length; index++) {
              formData.append(key+'[]', fixModel[key][index])
            }
          } else {
            formData.append(key, fixModel[key])
          }
        });
        formData.append('assinatura', this.assinaturaFile);

        if (fixModel.id == 0) {
          criarUsuario(formData).then(() => {
            trataInsertSucesso(this, 'sistema.usuario.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        } else {
          atualizarUsuario(formData).then(() => {
            trataUpdateSucesso(this, 'sistema.usuario.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        }
      }
    },
    pesquisarConvenio(query) {
      const idsJaSelecionados = this.model.convenios.map((item) => item.id);

      return getLabelsConvenios({ filtrar: query, status: true, ignorarIds: idsJaSelecionados }).then(response => {
        return { data: response.data.resultado }
      })
    },
    selecionarConvenio(convenios) {
      this.model.convenios = convenios;
    },
    pesquisarHospital(query) {
      const idsJaSelecionados = this.model.hospitais.map((item) => item.id);

      return getLabelsHospitais({ filtrar: query, status: true, ignorarIds: idsJaSelecionados }).then(response => {
        return { data: response.data.resultado }
      })
    },
    selecionarHospital(hospitais) {
      this.model.hospitais = hospitais;
    },
    pesquisarPerfil(query) {
      const idsJaSelecionados = this.model.perfis.map((item) => item.id);

      return getLabelsPerfis({ filtrar: query, status: true, ignorarIds: idsJaSelecionados, representacaoId: this.model.representacaoId }).then(response => {
        return { data: response.data.resultado }
      })
    },
    selecionarPerfil(perfis) {
      this.model.perfis = perfis;
    },
  },
};
</script>

<style lang="scss" scoped>
.separador {
  display: block;
  border-top: 1px solid rgba(15, 34, 58, 0.12);
  margin: 0 calc(-1 * var(--bs-card-spacer-x));
}
</style>